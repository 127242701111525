import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from '../../../../atoms/Buttons/Base/Base';
import { SearchSmallIcon } from '../../../../atoms/Icons/Styleguide/SearchSmallIcon';
import { Input } from '../../../../atoms/Input/Input';
import { UrlService } from '../../../../services/UrlService';
import styles from './MyLanguagePanel.css';

type Country = {
    searchField: string;
    metaData: {
        countryCode: string;
        url: string;
    };
};

const countriesMap: Map<string, string> = new Map<string, string>();
countriesMap.set('us', 'English(US)');
countriesMap.set('fr', 'French');

const getLanguageUrl = (code: string): string => {
    return code === 'us' ? '/profile/' : `/${code}/profile/`;
};

const countries: Country[] = Array.from(countriesMap.entries()).map(([countryCode]) => ({
    searchField: countriesMap.get(countryCode),
    metaData: { countryCode: countryCode, url: getLanguageUrl(countryCode) },
}));

const filterCountries = (items: Country[], value: string): Country[] => {
    if (value.trim().length === 0) {
        return items;
    }
    const valueInLowerCase = value.toLowerCase();

    return items.filter((i) => {
        return i.searchField.toLowerCase().includes(valueInLowerCase);
    });
};

export const MyLanguagePanel = React.memo(() => {
    const [value, setValue] = useState('');
    const { t } = useTranslation();
    return (
        <>
            <p className={styles.warningMessage}>Changing your language will refresh the website</p>
            <div className={styles.content}>
                <div className={styles.input}>
                    <Input
                        value={value}
                        onValueChange={setValue}
                        placeholder={t('LANGUAGE_PLACEHOLDER')}
                        name="search-language"
                    />
                    <SearchSmallIcon className={styles.searchIcon} />
                </div>
                <ul>
                    {filterCountries(countries, value).map((item) => {
                        return (
                            <li key={item.searchField}>
                                <Button
                                    className={styles.countryListItem}
                                    fullWidth
                                    onClick={() => {
                                        window.location.href = item.metaData.url;
                                    }}
                                >
                                    <img
                                        alt="flag-icon"
                                        className={styles.countryFlag}
                                        src={UrlService.toCDNUrl(
                                            `/icons/flags-icons/${item.metaData.countryCode.toLowerCase()}.png`
                                        )}
                                    />
                                    <span className={styles.countryName}>{item.searchField}</span>
                                </Button>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
});
