import React from 'react';

import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { AvatarsPanel } from './Panels/AvatarsPanel/AvatarsPanel';
import { BirthdayPanel } from './Panels/BirthdayPanel/BIrthdayPanel';
import { CountryPickerPanel } from './Panels/CountryPickerPanel/CountryPickerPanel';
import { DeleteAccountPanel } from './Panels/DeleteAccountPanel/DeleteAccountPanel';
import { EmailPanel } from './Panels/EmailPanel/EmailPanel';
import { GenderPanel } from './Panels/GenderPanel/GenderPanel';
import { MyLanguagePanel } from './Panels/MyLanguagePanel/MyLanguagePanel';
import { AvatarNavigationTabs } from './Panels/NavigationTabs/AvatarNavigationTabs';
import { PhoneNumberPanel } from './Panels/PhoneNumberPanel/PhoneNumberPanel';
import { UserNamePanel } from './Panels/UserNamePanel/UserNamePanel';
import styles from './RightSlideInPanel.css';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { ArkadiumFullLogo } from '../../atoms/Icons/Styleguide/ArkadiumLogo/ArkadiumLogo';
import { ArrowRightIcon } from '../../atoms/Icons/Styleguide/ArrowRightIcon';
import { CloseIcon } from '../../atoms/Icons/Styleguide/CloseIcon';
import { Responsive } from '../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../constants/ArkCssBreakpoints';
import { ProfileRightSideRoutes } from '../../constants/Pages';
import { UserModel } from '../../models/User/UserModel';
import { TabRouter } from '../../molecules/TabRouter/TabRouter';
import { setProfilePanelState } from '../../store/ducks/layout';
import { UserEffects } from '../../store/effects/user.effects';

type Props = {
    isOpened: boolean;
    caption: string;
    targetPanel: ProfileRightSideRoutes;
    user: UserModel;
    dispatch: any;
};

const WithFieldMeta =
    (Component) =>
    ({ field, hint, validation }) =>
    (props) => {
        const newProps = {
            ...props,
            field,
            hint,
            validation,
        };
        return <Component {...newProps} />;
    };

const FirstNamePanel = WithFieldMeta(UserNamePanel)({
    field: 'firstName',
    hint: 'Enter your first name',
    validation: 'firstName',
});
const LastNamePanel = WithFieldMeta(UserNamePanel)({
    field: 'lastName',
    hint: 'Enter your last name',
    validation: 'lastName',
});
const ScreenNamePanel = WithFieldMeta(UserNamePanel)({
    field: 'name',
    hint: 'Enter your screen name',
    validation: 'screenName',
});
//TODO: We Should refactor ProfileRightSideRoutes
const routes = new Map<ProfileRightSideRoutes, React.ElementType>();
routes.set(ProfileRightSideRoutes.NOOP, () => null);
routes.set(ProfileRightSideRoutes.AVATARS_PANEL, AvatarsPanel);
routes.set(ProfileRightSideRoutes.AVATAR_CUSTOMIZE_PANEL, AvatarsPanel);
routes.set(ProfileRightSideRoutes.SCREEN_NAME_PANEL, ScreenNamePanel);
routes.set(ProfileRightSideRoutes.FIRST_NAME_PANEL, FirstNamePanel);
routes.set(ProfileRightSideRoutes.LAST_NAME_PANEL, LastNamePanel);
routes.set(ProfileRightSideRoutes.COUNTRY_PICKER_PANEL, CountryPickerPanel);
routes.set(ProfileRightSideRoutes.MY_LANGUAGE_PANEL, MyLanguagePanel);
routes.set(ProfileRightSideRoutes.DELETE_ACCOUNT_PANEL, DeleteAccountPanel);
routes.set(ProfileRightSideRoutes.BIRTHDAY_PANEL, BirthdayPanel);
routes.set(ProfileRightSideRoutes.PHONE_NUMBER_PANEL, PhoneNumberPanel);
routes.set(ProfileRightSideRoutes.GENDER_PANEL, GenderPanel);
routes.set(ProfileRightSideRoutes.EMAIL_PANEL, EmailPanel);

const ANIMATION_DURATION = 600;

class RightSlideInPanelBase extends React.PureComponent<Props> {
    readonly state = {
        isFocusTrapActive: false,
    };

    private mediaQueryWatcher: MediaQueryList;

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (!this.props.isOpened && nextProps.isOpened) {
            document.body.style.overflow = 'hidden';
            document.body.style.overflowY = 'hidden';
        } else if (!nextProps.isOpened) {
            document.body.style.overflow = 'visible';
            document.body.style.overflowY = 'visible';
        }
    }

    componentDidMount() {
        this.mediaQueryWatcher = window.matchMedia(`(min-width:${ArkCssBreakpoints.ARK_SMALL_DESKTOP}px)`);
        this.setState({ isFocusTrapActive: this.mediaQueryWatcher.matches });

        this.mediaQueryWatcher.addEventListener('change', this.handleMediaQuery);
    }

    componentWillUnmount() {
        this.mediaQueryWatcher.removeEventListener('change', this.handleMediaQuery);
        this.unmountTrap();
    }

    saveUser = (updatedUser: any) => {
        this.props.dispatch(
            UserEffects.updateUser({
                ...updatedUser,
            })
        );
    };

    closePanel = () => {
        const { dispatch, caption, targetPanel } = this.props;
        dispatch(
            setProfilePanelState({
                isOpened: false,
                caption: caption,
                target: targetPanel,
            })
        );
    };

    handleOpenTab = (
        targetPanelValue: ProfileRightSideRoutes.AVATARS_PANEL | ProfileRightSideRoutes.AVATAR_CUSTOMIZE_PANEL
    ) => {
        this.props.dispatch(
            setProfilePanelState({
                isOpened: true,
                caption: '',
                target: targetPanelValue,
            })
        );
    };

    isAvatarPanels = () => {
        const { targetPanel } = this.props;
        const isAvatar =
            targetPanel === ProfileRightSideRoutes.AVATARS_PANEL ||
            targetPanel === ProfileRightSideRoutes.AVATAR_CUSTOMIZE_PANEL;
        return isAvatar;
    };

    handleMediaQuery = (event) => {
        this.setState({ isFocusTrapActive: event.matches });
    };

    unmountTrap = () => this.setState({ isFocusTrapActive: false });

    /*
     * We need to wait till side panel animation will be finished.
     * Only after that it is safe to send focus event.
     * In another way, side panel will be opened without animation at all.
     * Check focus-trap-react docs for more details.
     */
    checkWhenFocusTrap = (): Promise<void> => {
        return new Promise((resolve) => {
            setTimeout(resolve, ANIMATION_DURATION);
        });
    };

    render() {
        const { isOpened, targetPanel, user, dispatch } = this.props;
        const focusTrapOptions: FocusTrap.Props['focusTrapOptions'] = {
            initialFocus: `[data-element-description=side-menu-hide]`,
            checkCanFocusTrap: this.checkWhenFocusTrap,
            allowOutsideClick: true,
        };

        return (
            <CSSTransition
                in={isOpened}
                timeout={ANIMATION_DURATION}
                classNames={{
                    enter: styles.myNodeEnter,
                    enterActive: styles.myNodeEnterActive,
                    exit: styles.myNodeExit,
                    exitActive: styles.myNodeExitActive,
                    exitDone: styles.myNodeExitDone,
                }}
                unmountOnExit
            >
                <FocusTrap active={this.state.isFocusTrapActive} focusTrapOptions={focusTrapOptions}>
                    <div
                        className={classNames(styles.panel, {
                            [styles.leveled]: isOpened,
                        })}
                        role="dialog"
                        aria-modal="true"
                        aria-label="avatar settings"
                    >
                        <div
                            className={classNames(styles.header, {
                                [styles.avatarsPanel]: this.isAvatarPanels(),
                            })}
                        >
                            <div
                                className={classNames(styles.panelCaption, styles[targetPanel], {
                                    [styles.avatarsPanel]: this.isAvatarPanels(),
                                })}
                            >
                                {this.isAvatarPanels() ? (
                                    <AvatarNavigationTabs activeTab={targetPanel} handleClick={this.handleOpenTab} />
                                ) : (
                                    <ArkadiumFullLogo textHoverColor="#dc1e34" arrowHoverColor="#dc1e34" />
                                )}
                            </div>

                            <div>
                                <Responsive maxWidth={1024}>
                                    <button className={styles.closeBtn} onClick={this.closePanel}>
                                        <CloseIcon />
                                    </button>
                                </Responsive>
                                <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                                    <button
                                        className={styles.navButton}
                                        onClick={this.closePanel}
                                        data-element-description="side-menu-hide"
                                    >
                                        <ArrowRightIcon className={styles.navButtonIcon} />
                                        <I18nText keyName="SIDEBAR_HIDE" className={styles.iconCaption} />
                                    </button>
                                </Responsive>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <TabRouter
                                activeState={targetPanel}
                                componentsMap={routes}
                                saveUser={this.saveUser}
                                user={user}
                                closePanel={this.closePanel}
                                dispatch={dispatch}
                            />
                        </div>
                    </div>
                </FocusTrap>
            </CSSTransition>
        );
    }
}

export default connect((state) => ({
    isOpened: state.profilePanel.isOpened,
    caption: state.profilePanel.caption,
    targetPanel: state.profilePanel.target,
    user: state.user,
}))(RightSlideInPanelBase);
