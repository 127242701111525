import React, { useState } from 'react';

import { BorderedButton } from '../../../../atoms/Buttons/BorderedButton/BorderedButton';
import { Input } from '../../../../atoms/Input/Input';
import { Responsive } from '../../../../atoms/Layout/Responsive';
import { MobilePanelFooter } from '../../../../atoms/MobilePanelFooter/MobilePanelFooter';
import { ArkCssBreakpoints } from '../../../../constants/ArkCssBreakpoints';
import styles from './DeleteAccountPanel.css';

export const DeleteAccountPanel = React.memo(() => {
    const [value, setValue] = useState('');
    return (
        <div className={styles.content}>
            <div className={styles.message}>Please enter your password to delete your account</div>
            <Input value={value} onValueChange={setValue} />
            <Responsive maxWidth={1024}>
                <MobilePanelFooter>
                    <BorderedButton filled caption="Delete my account" />
                </MobilePanelFooter>
            </Responsive>
            <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                <MobilePanelFooter>
                    <BorderedButton caption="Cancel" />

                    <BorderedButton filled caption="Delete my account" />
                </MobilePanelFooter>
            </Responsive>
        </div>
    );
});
